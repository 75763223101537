import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import Loader from "../Loader";
import ObfuscatedEmail from "../ObfuscatedEmail";
import styles from "./button.module.scss";

const Button = ({
  color = 'blue1',
  roundedCorners,
  url,
  content,
  className = null,
  type = 'link',
  to,
  isLoading = false,
  onClick = null
}) => (
  <div className={styles.buttonWrapper}>
    {isLoading &&
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>}
    {type === 'link' &&
    <Link
      onClick={onClick}
      to={to}
      className={cx({
        [styles[`${color}Wrapper`]]: true,
        [styles.rounded]: roundedCorners,
        [className]: className,
      })}
    >
      {content}
    </Link>}
    {type === 'external-link' &&
    <a
      onClick={onClick}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={cx({
        [styles[`${color}Wrapper`]]: true,
        [styles.rounded]: roundedCorners,
        [className]: className,
      })}
    >
      {content}
    </a>}
    {type === 'email-button' &&
    <ObfuscatedEmail
      onClick={onClick}
      email={url}
      className={cx({
        [styles[`${color}Wrapper`]]: true,
        [styles.rounded]: roundedCorners,
        [className]: className,
      })}
      label={content}
    />}
    {type === 'submit' &&
    <button
      onClick={onClick}
      type="submit"
      className={cx({
        [styles[`${color}Wrapper`]]: true,
        [styles.rounded]: roundedCorners,
        [className]: className,
      })}
    >
      {content}
    </button>}
  </div>
)
export default Button;
