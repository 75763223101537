import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Button from "../Button";
import StarsParticles from '../StarsParticles';
import ContactWithIcon from './ContactWithIcon';
import logoColor from '../../images/logo-color.svg';
import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          footerBanner: file(relativePath: { eq: "footer-background.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          emailIcon: file(relativePath: { eq: "email-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          locationIcon: file(relativePath: { eq: "location-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          phoneIcon: file(relativePath: { eq: "phone-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <footer className={styles.outerContainer}>
          <div className={styles.container}>
            <section className={styles.innerContainer}>
              <h2 className={styles.heading}>Interested in discussing your next project?</h2>
              <p>Let's build something great.</p>
              <Button type="email-button" content="Contact us!" url="hello@cybertent.co.uk" />
            </section>
            <Img className={styles.heroBanner} fluid={data.footerBanner.childImageSharp.fluid} />
            <StarsParticles />
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.colMid}>
              <a href="/#home-section" className={styles.logoWrap}>
                <img className={styles.logo} src={logoColor} alt="Cybertent" />
              </a>
              <a className={styles.link} href="/#why-us-section">Why Us</a>
              <a className={styles.link} href="/#development-section">How We Work</a>
              <a className={styles.link} href="/#services-section">Our Services</a>
            </div>
            <div className={styles.colMid}>
              <h3>Contact us</h3>
              <div className={styles.contactContainer}>
                <ContactWithIcon
                  icon={data.emailIcon.childImageSharp.fluid}
                  email="hello@cybertent.co.uk"
                  text="hello@cybertent.co.uk"
                />
                <ContactWithIcon
                  icon={data.phoneIcon.childImageSharp.fluid}
                  tel="+442080991269"
                  text="+44 20 80991269"
                />
              </div>
            </div>
            <div className={styles.colMid}>
              <h3>United Kingdom</h3>
              <ContactWithIcon
                icon={data.locationIcon.childImageSharp.fluid}
                text="63/66 Hatton Garden, Fifth Floor, Suite 23, London, EC1N 8LE"
              />
            </div>
            <div className={styles.colMid}>
              <h3>Poland</h3>
              <ContactWithIcon
                icon={data.locationIcon.childImageSharp.fluid}
                text="Mieleckiego 10/503, 40-013 Katowice"
              />
            </div>
          </div>
          <div className={styles.bottomLineWrap}>
            <p>© 2019 Cybertent Ltd. All rights reserved. <Link className={styles.cookieLink} to="/cookies">Cookie policy</Link></p>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
