import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import Button from '../Button';
import StarsParticles from '../StarsParticles';
import styles from "./hero.module.scss";

const HeroBanner = ({ title, subtitle, hasHomeButton=false }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          homeHeroBanner: file(relativePath: { eq: "hero-banner-background.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 99) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          homeHeroIcon: file(relativePath: { eq: "hero-banner-mobile-development.png" }) {
            childImageSharp {
              fluid(maxWidth: 1100, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
        }
      `}
      render={data => (
        <div className={styles.container} id="home-section">
          <div className={styles.contentContainer}>
            <div className={styles.bannerIconContainer}>
              <Img className={styles.bannerIcon} fluid={data.homeHeroIcon.childImageSharp.fluid} alt="Mobile applications development" />
            </div>
            <div className={styles.textContainer}>
              <h1 className={styles.heading}>{title}</h1>
              <p className={styles.paragraph}>{subtitle}</p>
              {hasHomeButton && <Button content="Find out how" to="/#why-us-section" />}
            </div>
          </div>
          <Img alt="Hero banner background" className={styles.heroBanner} fluid={data.homeHeroBanner.childImageSharp.fluid} />
          <StarsParticles />
        </div>
      )}
    />
  )
}

export default HeroBanner
