import React, { useState, useEffect } from 'react';
import Particles from 'react-particles-js';
import star from "../../images/animated-star.png"

const settings = {
  "particles": {
    "number": {
      "value": 17,
      "density": {
        "enable": true,
        "value_area": 500
      }
    },
    "line_linked": {
      "enable": false,
    },
    "move": {
      "direction": "none",
      "speed": 1
    },
    "size": {
      "value": 9,
      "random":	true,
      "anim": {
        "enable":	true,
        "speed": 4,
        "size_min":	7,
        "sync":	false,
      }
    },
    "opacity": {
      "value": 0.7,
      "random":	true,
      "anim": {
        "enable": true,
        "speed": 1000,
        "opacity_min": 0.2,
        "sync":	false,
      }
    },
    "shape": {
      "type": "images",
      "images": [
        {
          "src": star,
          "height": 20,
          "width": 20
        },
      ]
    }
  },
  "interactivity": {
    "events": {
      "onclick": {
        "enable": true,
        "mode": "push"
      }
    },
    "modes": {
      "push": {
        "particles_nb": 1
      }
    }
  },
  "retina_detect": true
}

export default className => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsActive(true), 200)
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [])
  return (
    isActive ?
    <Particles
      params={settings}
      style={{
        height: '100%',
        width: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
      }}
    /> : null
  )
}
