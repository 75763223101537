import { Link } from 'gatsby';
import React from 'react';
import { Watch } from 'scrollmonitor-react';
// import Menu from 'react-burger-menu/lib/menus/slide'
import cx from 'classnames';
import Button from "../Button";
import BurgerMenu from "./BurgerMenu";
import logoColor from '../../images/logo-color.svg';
import logoWhite from '../../images/logo-white.svg';
import styles from "./header.module.scss";

class Header extends React.PureComponent {
  constructor() {
    super();
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  getListItem = (elem, mobileDisabled = false) => {
    if (mobileDisabled) {
      return <li className={cx(styles.desktopMenuItem, styles.mobileDisabled)}>{elem}</li>;
    }
    return <li className={styles.desktopMenuItem}>{elem}</li>;
  }

  render () {
    const { isInViewport } = this.props;
    const isInViewport2 = this.isComponentMounted ? isInViewport : true;
    const isNotInViewport2 = this.isComponentMounted && !isInViewport;
    return (
      <header className={styles.wrapper}>
        <div className={cx({
          [styles.outerContainer]: true,
          [styles.outerContainerScrolled]: isNotInViewport2,
        })}>
          <div className={styles.container}>
            <nav className={cx({
              [styles.innerContainer]: isInViewport2,
              [styles.innerContainerScrolled]: !isInViewport2,
              [styles.isAnimated]: true,
            })}>
              <Link to="/" className={styles.logoWrap}>
                <img className={styles.logo} src={isInViewport2 ? logoWhite : logoColor} alt="Cybertent" />
              </Link>
              <ul className={styles.desktopMenuWrap}>
                {this.getListItem(<a className={styles.desktopMenuLink} href="/#why-us-section">Why Us</a>)}
                {this.getListItem(<a className={styles.desktopMenuLink} href="/#development-section">How We Work</a>)}
                {this.getListItem(<a className={styles.desktopMenuLink} href="/#our-work-section">Projects</a>)}
                {this.getListItem(<a className={styles.desktopMenuLink} href="/#services-section">Services</a>)}
                {this.getListItem(<Button type="email-button" content="Contact us!" url="hello@cybertent.co.uk" />)}
              </ul>
              <div className={styles.mobileMenuWrap}>
                <BurgerMenu isDark={!isInViewport2} />
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
}

export default Watch(Header);
