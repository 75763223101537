import React from 'react';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';
import BurgerMenuBtn from "./BurgerMenuBtn";
import Button from "../../Button";
import styles from "./burger-menu.module.scss";

class BurgerMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false,
      isDisplayed: false,
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleClickOutside() {
    if (this.state.isDisplayed) {
      this.toggleMenu();
    }
  }

  toggleMenu = () => {
    if (this.state.isDisplayed) {
      clearTimeout(this.timeout);
      this.setState({ isVisible: false });
      this.timeout = setTimeout(() => this.setState({ isDisplayed: false }), 400);
    } else if (!this.state.isDisplayed) {
      clearTimeout(this.timeout);
      this.setState({ isDisplayed: true });
      this.timeout = setTimeout(() => this.setState({ isVisible: true }), 100);
    }
  }

  render () {
    const { isDisplayed, isVisible } = this.state;
    return (
      <div className={styles.menuWrapper}>
        <BurgerMenuBtn isOpen={isVisible} toggleMenu={this.toggleMenu} isDark={this.props.isDark || isVisible} />
        <ul className={cx({
          [styles.popupMenu]: true,
          [styles.popupDisplayed]: isDisplayed,
          [styles.popupVisible]: isVisible,
        })}>
          <li className={styles.menuItem}>
            <a className={styles.menuLink} href="/#why-us-section" onClick={this.toggleMenu}>Why Us</a>
          </li>
          <li className={styles.menuItem}>
            <a className={styles.menuLink} href="/#development-section" onClick={this.toggleMenu}>How We Work</a>
          </li>
          <li className={styles.menuItem}>
            <a className={styles.menuLink} href="/#our-work-section" onClick={this.toggleMenu}>Projects</a>
          </li>
          <li className={styles.menuItem}>
            <a className={styles.menuLink} href="/#services-section" onClick={this.toggleMenu}>Services</a>
          </li>
          <li className={styles.menuItem2}>
            <Button
              type="email-button"
              className={styles.dropdownMenuButton}
              content="Contact us!"
              url="hello@cybertent.co.uk"
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default onClickOutside(BurgerMenu);
