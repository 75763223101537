import React from 'react';
import Img from 'gatsby-image';
import ObfuscatedEmail from '../../ObfuscatedEmail';
import styles from './contactWithIcon.module.scss';

const ContactWithIcon = ({ icon, text, email, tel }) => (
  <div className={styles.container}>
    <div className={styles.col1}>
      {!!icon && <Img className={styles.icon} fluid={icon} />}
    </div>
    <div className={styles.col2}>
      {email || tel ?
      <ObfuscatedEmail className={styles.linkText} email={email} tel={tel} label={text} /> :
      <p className={styles.content}>{text}</p>}
    </div>
  </div>
)

export default ContactWithIcon;
