import React from 'react';
import cx from 'classnames';
import styles from "./burger-menu-btn.module.scss";

const BurgerMenuBtn = ({ isOpen = false, toggleMenu, isDark = false }) => (
  <div onClick={toggleMenu} className={cx({ [styles.hamburger]: true, [styles.isActive]: isOpen })}>
    <div className={styles.hamburgerBox}>
      <div className={cx({
        [styles.hamburgerInner]: true,
        [styles.isDark]: isDark,
      })} />
    </div>
  </div>
)
export default BurgerMenuBtn;
